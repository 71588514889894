<template>
  <TextSpeachDescription />
</template>

<script>
import TextSpeachDescription from '@/components/services/text_to_speach/TextSpeachDescription.vue';

export default {
  components: {
    TextSpeachDescription
  }
};
</script>

<style></style>
