<template>
  <ul v-if="isTitleAndText">
    <li v-for="item in text" :key="item.id" :class="$style.listItem">
      <the-list-item>
        <template #icon>
          <the-check-mark v-if="checkMark"></the-check-mark>
          <the-square v-else-if="square"></the-square>
          <span :class="$style.text">
            <b>{{ item.title }}</b>
          </span>
        </template>
        <slot>
          <div :class="$style.description">{{ item.text }}</div>
        </slot>
      </the-list-item>
    </li>
  </ul>
  <ul v-else>
    <li v-for="item in text" :key="item" :class="$style.listItem">
      <the-list-item>
        <template #icon>
          <the-check-mark v-if="checkMark"></the-check-mark>
          <the-square v-else-if="square"></the-square>
          <div :class="$style.text">{{ item }}</div>
        </template>
      </the-list-item>
    </li>
  </ul>
</template>

<script>
import TheListItem from '@/components/shared/TheListItem.vue';

export default {
  props: {
    text: {
      type: Array || String,
      require: true
    },
    checkMark: {
      type: Boolean,
      require: false,
      default: true
    },
    square: {
      type: Boolean,
      require: false,
      default: false
    },
    isTitleAndText: {
      type: Boolean,
      require: false,
      default: false
    }
  },

  components: {
    TheListItem,
    TheCheckMark: () => import('@/components/shared/marks/TheCheckMark.vue'),
    TheSquare: () => import('@/components/shared/marks/TheSquare.vue')
  }
};
</script>

<style module>
.listItem {
  line-height: 2;
}

.text {
  display: block;
  width: calc(100% - 1.625rem);
  line-height: 1.5;
  padding-left: 0.25rem;
}

.description {
  padding-left: 1.625rem;
}
</style>
