<template>
  <div :class="$style.itemWrap" class="dflex valign-center">
    <slot name="icon"></slot>
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style module>
.itemWrap {
  margin-bottom: 0.75rem;
}
</style>
