<template>
  <ServicesDescription @connectService="connectService" :isPageWrapper="true">
    <template #title>
      Преобразование текста в речь
    </template>
    <template #body>
      <p>
        Сервис «text-to-speach» (Преобразование текста в речь) позволяет переводить текстовую
        информацию в голосовую.
      </p>
      <p>С помощью сервиса</p>
      <text-list :text="text" :checkMark="false" :square="true"></text-list>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import TextList from '@/components/shared/TextList.vue';

export default {
  data() {
    return {
      text: [
        'Записывайте голосовое приветствие для Ваших номеров',
        'Создавайте индивидуальное приветствие для «особых» клиентов',
        'Настраивайте интерактивное голосовое меню',
        'Записывайте информацию о режиме работы компании, проводимых акциях и предоставляемых услугах',
        'Выбирайте голос и его эмоциональную окраску, скорость чтения, которые больше всего подходят для Вашего сообщения'
      ]
    };
  },
  components: {
    ServicesDescription,
    TextList
  },

  methods: {
    connectService() {
      this.$router.push({
        path: '/create-statement',
        query: { st: 'addService', service: 'преобразование-текста-в-речь' }
      });
    }
  }
};
</script>

<style></style>
